import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import JsonPreview from "./JsonPreview";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: "#fff",
  },
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={2000} ref={ref} {...props} />;
});

const FormConfirmationModal = ({
  open,
  values,
  handleClose,
  handleOpen,
  handleSubmit,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-gray-700">
        Enregistrer les modifications
      </DialogTitle>
      <DialogContent>
        <JsonPreview content={values} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubmit} color="secondary" autoFocus>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormConfirmationModal;
