import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { teal } from "@material-ui/core/colors";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { useDebounce } from "use-debounce";
import { NavLinkAdapter } from "@fuse";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "absolute",
  },
  card: {
    maxWidth: 500,
    marginBottom: 16,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#efefef",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: teal[500],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationDialog(props) {
  const {
    open,
    title,
    message,
    action,
    id,
    payload,
    handleClose,
    confirmAction,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        {action === "changeBound" && (
          <Button onClick={() => confirmAction(id, payload)} color="secondary">
            Confirmer la réassociation
          </Button>
        )}
        {action === "unbound" && (
          <Button onClick={() => confirmAction(id)} color="secondary">
            Confirmer le détachement
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function IllustrationCard(props) {
  let {
    id,
    title,
    imageProcBase,
    imageCustom,
    category,
    name,
    tags,
    isPublished,
    rights,
    setUnbound,
    setChangeBound,
    from,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmState, setConfirmState] = useState({
    open: false,
    title: "",
    message: "",
    action: "",
    id: null,
    payload: {},
    handleClose: function () {
      return undefined;
    },
  });
  const [noOptionText, setNoOptionText] = useState(
    "Récupération des données en cours..."
  );
  const [active, setActive] = useState(true);
  const [debouncedInputValue] = useDebounce(inputValue, 800);

  useEffect(() => {
    let active = true;
    let accessToken = localStorage.getItem("jwt_access_token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    if (!open) {
      return undefined;
    }

    (async () => {
      setNoOptionText("Récupération des données en cours...");
      setOptions([]);
      if (active) {
        const request = axios.get(
          `${process.env.REACT_APP_VEGEBASE_API_URL}/admin/plantes`,
          {
            params: {
              q: debouncedInputValue ? debouncedInputValue : "",
            },
          }
        );
        request.then((response) => {
          const { plantes } = response.data;
          setOptions(response.data.plantes);
          setLoading(false);
          if (plantes.length === 0) {
            setNoOptionText("Aucune plante trouvée.");
          }
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [debouncedInputValue, open]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    }
  }, [open]);

  useEffect(() => {
    if (inputValue !== debouncedInputValue && open) {
      setLoading(true);
    }
  }, [inputValue, debouncedInputValue, open]);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const openFullscreen = () => {
    setOpenDialog(true);
  };
  const closeFullscreen = () => {
    setOpenDialog(false);
  };

  function handleOpenConformationDialog(params) {
    setConfirmState({ ...confirmState, ...params });
  }
  function closeConfirmDialog() {
    setConfirmState({ ...confirmState, open: false });
  }

  function handleUnbound(illustrationId) {
    closeConfirmDialog();
    setActive(false);
    setUnbound(illustrationId);
  }

  function handleChangeBound(illustrationId, data) {
    closeConfirmDialog();
    setActive(false);
    setChangeBound(illustrationId, data);
  }

  return (
    <div>
      <ConfirmationDialog {...confirmState} />
      <Dialog
        fullScreen
        open={openDialog}
        onClose={closeFullscreen}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeFullscreen}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {name ? `${name}` : <i>Nom de fichier indisponible</i>}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img
              alt={"fullscreen-plant"}
              src={`${imageProcBase}&dpr=3`}
              className="max-h-screen w-full object-contain"
            />
          </Grid>
        </Grid>
      </Dialog>
      <Slide in={active} direction="up" mountOnEnter unmountOnExit>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar aria-label="collection" className={classes.avatar}>
                <span role="img" aria-label="Panda">
                  🐼
                </span>
              </Avatar>
            }
            title={title}
            subheader={category}
          />
          <CardMedia
            className={classes.media}
            image={imageCustom}
            title={title}
          />
          <CardContent>
            <div
              className="overflow-hidden"
              style={{ maxHeight: "129px", minHeight: "129px" }}
            >
              <Typography
                gutterBottom
                variant="subtitle2"
                color="secondary"
                component="h6"
              >
                {name ? `${name}` : <i>Nom de fichier indisponible</i>}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                color="primary"
                component="h6"
              >
                {rights}
              </Typography>
              {tags.map((label, index) => (
                <Chip
                  key={index}
                  label={label}
                  size="small"
                  className="mb-3 mr-3"
                />
              ))}
            </div>
          </CardContent>
          <CardActions disableSpacing>
            {isPublished ? (
              <Tooltip title="Publié" placement="top" aria-label="published">
                <PublicIcon color="secondary" className="m-12" />
              </Tooltip>
            ) : (
              <Tooltip
                title="Non Publié"
                placement="top"
                aria-label="unpublished"
              >
                <LockIcon color="primary" className="m-12" />
              </Tooltip>
            )}

            <Tooltip
              title="Afficher en plein écran"
              placement="top"
              aria-label="detach-tooltip"
            >
              <IconButton aria-label="fullscreen" onClick={openFullscreen}>
                <FullscreenIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Éditer les métadonnées"
              placement="top"
              aria-label="detach-tooltip"
            >
              <IconButton
                aria-label="link"
                component={NavLinkAdapter}
                to={{
                  pathname: `/admin/i/${id}`,
                  state: { from: from, backMessage: `Retour à ${title}` },
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Plus d'actions"
              placement="left"
              aria-label="more-actions"
            >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                color="primary"
                component="h6"
              >
                Modifier l'association
              </Typography>
              <Autocomplete
                id="async-taxa-autocomplete"
                className="w-full mr-8"
                open={open}
                value={value}
                onChange={(event, newValue) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onOpen={() => {
                  setOptions([]);
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                  setOptions([]);
                  setLoading(false);
                }}
                getOptionSelected={(option, value) =>
                  `${option.valid_id} - ${option.valid_taxon}` ===
                  `${value.valid_id} - ${value.valid_taxon}`
                }
                getOptionLabel={(option) =>
                  `${option.valid_id} - ${option.valid_taxon}`
                }
                options={options}
                loading={loading}
                loadingText={noOptionText}
                noOptionsText={"Aucune plante trouvé"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nouveau taxon à associer"
                    variant="outlined"
                    multiline
                    rowsMax={2}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(option) => {
                  return (
                    <Grid container alignItems="center" className="mb-8">
                      <Grid item>
                        {option.image ? (
                          <Avatar
                            alt={option.slug}
                            src={option.image}
                            className="mr-16"
                          />
                        ) : (
                          <Skeleton
                            variant="circle"
                            height={32}
                            width={32}
                            className="mr-16"
                          />
                        )}
                      </Grid>
                      <Grid item xs>
                        <Typography variant="subtitle2" color="textSecondary">
                          <i>{option.taxon}</i>
                        </Typography>
                        {option.status === "synonyme" && (
                          <Typography variant="body2" color="textSecondary">
                            synonyme de {option.valid_taxon}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  );
                }}
              />
              <div className="flex flex-row-reverse w-full">
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  disabled={inputValue === ""}
                  className="mt-8 mb-24 w-1/2"
                  aria-label="detach"
                  value="legacy"
                  onClick={() =>
                    handleOpenConformationDialog({
                      open: true,
                      title: "Confirmer la réassociation",
                      message: `Vous allez modifier l'association de "${name}" depuis ${title} vers ${value.taxon}.`,
                      id: id,
                      payload: value,
                      action: "changeBound",
                      handleClose: closeConfirmDialog,
                      confirmAction: handleChangeBound,
                    })
                  }
                >
                  Réassocier
                </Button>
              </div>

              <Typography gutterBottom variant="h6" component="h6">
                Actions sensibles
              </Typography>
              <div className="flex">
                <div className="flex-auto w-1/2 mr-4">
                  <Button
                    variant="contained"
                    className="w-full mx-auto mt-16 mb-16 bg-red-700 hover:bg-grey-50 text-grey-50 hover:text-black"
                    aria-label="archive"
                    value="legacy"
                    disabled
                  >
                    Archiver
                  </Button>
                </div>
                <div className="flex-auto w-1/2 ml-4">
                  <Button
                    variant="contained"
                    className="w-full mx-auto mt-16 mb-16 bg-red-700 hover:bg-grey-50 text-grey-50 hover:text-black"
                    aria-label="detach"
                    value="legacy"
                    onClick={() =>
                      handleOpenConformationDialog({
                        open: true,
                        title: "Confirmer le détachement",
                        message: `Vous allez supprimer l'association de "${name}" à ${title}.`,
                        id: id,
                        payload: null,
                        action: "unbound",
                        handleClose: closeConfirmDialog,
                        confirmAction: handleUnbound,
                      })
                    }
                  >
                    Détacher
                  </Button>
                </div>
              </div>
            </CardContent>
          </Collapse>
        </Card>
      </Slide>
    </div>
  );
}

export default IllustrationCard;
