import React, { useState } from "react";
import { Card, CardContent, Typography, Tabs, Tab } from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { FuseAnimate } from "@fuse";
import { Link } from "react-router-dom";
import clsx from "clsx";
import JWTLoginTab from "./tabs/JWTLoginTab";
import OAuthLoginTab from "./tabs/OAuthLoginTab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      darken(theme.palette.primary.dark, 0.5) +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
}));

function Login() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  function handleTabChange(event, value) {
    setSelectedTab(value);
  }

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0"
      )}
    >
      <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
        <FuseAnimate animation="transition.expandIn">
          <img
            className="w-128 mb-32"
            src="assets/images/logos/floriscope.png"
            alt="logo"
          />
        </FuseAnimate>

        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <Typography variant="h3" color="inherit" className="font-light">
            Bienvenue sur FLORISCOPE!
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={400}>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="max-w-512 mt-16"
          >
            Ce site est en cours de développement. Son accès est réservé à
            l'équipe Floriscope et aux contributeurs actifs du projet.
          </Typography>
        </FuseAnimate>
      </div>

      <FuseAnimate animation={{ translateX: [0, "100%"] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
            <Typography variant="h6" className="text-center md:w-full mb-48">
              CONNECTEZ-VOUS À VOTRE COMPTE
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              className="w-full mb-32"
            >
              <Tab
                icon={
                  <img
                    className="h-40"
                    src="assets/images/logos/floriscope.png"
                    alt="floriscope"
                  />
                }
                className="min-w-0"
                label="Floriscope"
              />
              <Tab
                icon={
                  <img
                    className="h-40"
                    src="assets/images/logos/OAuth.svg"
                    alt="oauth"
                  />
                }
                className="min-w-0"
                label="OAuth"
              />
            </Tabs>

            {selectedTab === 0 && <JWTLoginTab />}
            {selectedTab === 1 && <OAuthLoginTab />}

            <div className="flex flex-col items-center justify-center pt-32">
              <span className="font-medium">
                Rejoignez la communaute Floriscope!
              </span>
              <Link className="font-medium" to="/register">
                Créer un compte
              </Link>
              <Link className="font-medium mt-8" to="/">
                Retour à l'accueil
              </Link>
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default Login;
