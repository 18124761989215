import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import auth0Service from "app/services/auth0Service";
import * as authActions from "app/auth/store/actions";
import * as Actions from "app/store/actions";
import { useDispatch } from "react-redux";

function OAuthLoginTab(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    showDialog();

    auth0Service.onAuthenticated(() => {
      dispatch(Actions.showMessage({ message: "Logging in with Auth0" }));

      auth0Service.getUserData().then(tokenData => {
        dispatch(authActions.setUserDataAuth0(tokenData));

        dispatch(Actions.showMessage({ message: "Logged in with Auth0" }));
      });
    });
  }, [dispatch]);

  function showDialog() {
    auth0Service.login();
  }

  return (
    <div className="w-full">
      <Button
        className="w-full my-48"
        color="primary"
        variant="contained"
        onClick={showDialog}
        disabled={true}
      >
        work in progress
      </Button>
    </div>
  );
}

export default OAuthLoginTab;
