import mock from "../mock";
import { FuseUtils } from "@fuse";

const bulkUpsertsDB = {
  bulkUpserts: [
    {
      id: FuseUtils.generateGUID(),
      createdAt: "14/05/2020",
      createdBy: {
        authorName: "Floriscope",
        authorEmail: "floriscope@plante-et-cite.fr",
        authorAvatar: "",
      },
      totalRows: 56,
      validRows: 40,
      blockingErrors: 16,
      nonBlockingErrors: 2,
      processStatus: "failed",
      processReportUrl: "",
      importedAt: null,
      createdItemsCount: null,
      updatedItemsCount: null,
    },
    {
      id: FuseUtils.generateGUID(),
      createdAt: "15/05/2020",
      createdBy: {
        authorName: "Floriscope",
        authorEmail: "floriscope@plante-et-cite.fr",
        authorAvatar: "",
      },
      totalRows: 80,
      validRows: 80,
      blockingErrors: 0,
      nonBlockingErrors: 2,
      processStatus: "ready",
      processReportUrl: "",
      importedAt: null,
      createdItemsCount: null,
      updatedItemsCount: null,
    },
    {
      id: FuseUtils.generateGUID(),
      createdAt: "15/05/2020",
      createdBy: {
        authorName: "Floriscope",
        authorEmail: "floriscope@plante-et-cite.fr",
        authorAvatar: "",
      },
      totalRows: 512,
      validRows: 512,
      blockingErrors: 0,
      nonBlockingErrors: 20,
      processStatus: "ready",
      processReportUrl: "",
      importedAt: null,
      createdItemsCount: null,
      updatedItemsCount: null,
    },
    {
      id: FuseUtils.generateGUID(),
      createdAt: "09/05/2020",
      createdBy: {
        authorName: "Floriscope",
        authorEmail: "floriscope@plante-et-cite.fr",
        authorAvatar: "",
      },
      totalRows: 215,
      validRows: 215,
      blockingErrors: 0,
      nonBlockingErrors: 20,
      processStatus: "success",
      processReportUrl: "",
      importedAt: "26/05/2020",
      createdItemsCount: 115,
      updatedItemsCount: 100,
    },
  ],
};

mock.onGet("/api/plantes/bulk_upserts").reply((config) => {
  return [200, bulkUpsertsDB.bulkUpserts];
});

mock.onPost("/api/plantes/bulk_upserts/new").reply((request) => {
  // const data = JSON.parse(request.data);
  const data = { processStatus: "checking" };
  bulkUpsertsDB.bulkUpserts = [
    {
      ...data,
      id: FuseUtils.generateGUID(),
      createdAt: "21/05/2020",
      createdBy: {
        authorName: "Floriscope",
        authorEmail: "floriscope@plante-et-cite.fr",
        authorAvatar: "",
      },
      totalRows: 512,
      validRows: null,
      blockingErrors: null,
      nonBlockingErrors: null,
      processReportUrl: null,
      importedAt: null,
      createdItemsCount: null,
      updatedItemsCount: null,
    },
    ...bulkUpsertsDB.bulkUpserts,
  ];
  return [200, bulkUpsertsDB.bulkUpserts];
});

mock.onPost("/api/plantes/bulk_upserts/update").reply((request) => {
  const data = JSON.parse(request.data);

  bulkUpsertsDB.bulkUpserts = bulkUpsertsDB.bulkUpserts.map((bulkupsert) => {
    if (data.bulkupsert.id === bulkupsert.id) {
      return data.bulkupsert;
    }
    return bulkupsert;
  });

  return [200, bulkUpsertsDB.bulkUpserts];
});
