import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";

const RadioButtonGroup = ({ field: { onChange, name, value, ...rest }, form: { errors, touched, setFieldValue }, ...props }) => {
  const errorMessage = errors.name;
  const isTouched = touched.name;

  const change = (e, name, shouldValidate) => {
    e.persist();
    const inputValue = e.target.value;
    return setFieldValue(name, inputValue, shouldValidate);
  };

  return (
    <React.Fragment>
      <RadioGroup id="radioGroup" value={value || ""} onChange={(e) => change(e, name, true)} {...rest} {...props} row />
      <p className="text-red-700">{isTouched && errorMessage}</p>
    </React.Fragment>
  );
};

export default RadioButtonGroup;
