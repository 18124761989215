import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {},
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={2000} ref={ref} {...props} />;
});

const ExportModal = ({
  open,
  handleClose,
  handleOpen,
  handleExport,
  title,
  ...props
}) => {
  //   console.log("confirmationModal props", rest);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-gray-700">
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          <span role="img" aria-label="raised-hand-emoji">
            🙋
          </span>{" "}
          Impatient de récupérer votre export?
        </Typography>
        <Typography variant="body1" gutterBottom>
          <span role="img" aria-label="gear-emoji">
            ⚙
          </span>{" "}
          Cette action peut prendre quelques minutes. Elle sera donc réalisée en
          tâche de fond sur nos serveurs.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <span role="img" aria-label="mailbox-emoji">
            📭
          </span>{" "}
          Dès que le fichier sera prêt, il vous sera transmis par courrier
          électronique à l'adresse renseignée sur votre compte Floriscope.
        </Typography>
        <Typography variant="overline" gutterBottom>
          Cliquer sur Confirmer pour lancer le processus
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={handleExport}
          color="secondary"
          autoFocus
          variant="contained"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
