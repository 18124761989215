import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { DropzoneArea } from "material-ui-dropzone";
// import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import Hex from "crypto-js/enc-hex";
// import Base64 from "crypto-js/enc-base64";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: "#fff",
  },
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={2000} ref={ref} {...props} />;
});

// const CircularProgressWithLabel = (props) => {
//   return (
//     <Box position="relative" display="inline-flex">
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         top={0}
//         left={0}
//         bottom={0}
//         right={0}
//         position="absolute"
//         display="flex"
//         alignItems="center"
//         justify="center"
//       >
//         <Typography variant="caption" component="div" color="text.secondary">
//           {props.value}%
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

const LinearBufferProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="buffer" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          style={{ color: "black" }}
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
};

const UploadIllustrationsModal = ({
  open,
  message,
  dialogTitle = "Effectuer un nouvel import",
  confirmButtonContent = "Déclencher",
  handleClose,
  handleOpen,
  handleConfirm,
  ...rest
}) => {
  const classes = useStyles();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [canPerformUpload, setCanPerformUpload] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileTotalSize, setFileTotalSize] = useState(null);
  const [uploadBatchName, setUploadBatchName] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  // const submitConfirm = () => {
  //   handleConfirm();
  //   handleClose();
  // };

  let accessToken = localStorage.getItem("jwt_access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  const handleUploadBatchNameChange = (e) => {
    e.preventDefault();
    setUploadBatchName(e.target.value);
  };

  const handleFileChange = (files) => {
    console.log(files);
    if (Boolean(files.length > 0)) {
      setFileToUpload(files[0]);
      setFileTotalSize(files[0].size);
    } else {
      setFileToUpload(null);
      setFileTotalSize(null);
    }
    setCanPerformUpload(Boolean(files.length > 0));
  };

  const triggerUpload = () => {
    // Set the AWS Region
    const REGION = process.env.REACT_APP_AWS_REGION; //REGION
    const dropBucketName = process.env.REACT_APP_AWS_S3_BUCKET_GALLERY_UPLOAD;
    setCanPerformUpload(false);

    // 1. POST Create an UploadBatch with vegebase-api on form submit
    const request = axios.post(
      `${process.env.REACT_APP_VEGEBASE_API_URL}/admin/upload_batches`,
      {
        upload_batch: {
          title: uploadBatchName,
        },
      }
    );
    request
      .then((response) => {
        if (response.status === 201) {
          // 2. onFormSuccess, rename file with UploadBatch UUID and then trigger aws-direct-upload
          const uploadBatchData = response.data;
          (async () => {
            const stream = await fileToUpload.stream();
            const params = {
              Key: `${uploadBatchData.uuid}_${uploadBatchData.slug}.zip`,
              Bucket: dropBucketName,
              ContentType: fileToUpload.type,
              // 3. Not forget to compare ETag and File MD5
              // File Integrity Check not working yet
              // ContentMD5: x.toString(Hex),
              Body: stream,
              Metadata: {
                apiBaseUrl: process.env.REACT_APP_VEGEBASE_API_URL,
                authToken: accessToken,
                id: uploadBatchData.id.toString(),
              },
            };
            try {
              setUploadProgress(1);
              const parallelUploads3 = new Upload({
                client: new S3Client({
                  params: { Bucket: dropBucketName },
                  region: REGION,
                  credentials: {
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey:
                      process.env.REACT_APP_AWS_ACCESS_KEY_SECRET,
                  },
                }),
                // tags: [...], // optional tags
                queueSize: 4, // optional concurrency configuration
                partSize: 5242880, // optional size of each part
                leavePartsOnError: false, // optional manually handle dropped parts
                params: params,
              });

              parallelUploads3.on("httpUploadProgress", (progress) => {
                // console.log(progress);
                setUploadProgress(
                  Math.round((progress.loaded / fileTotalSize) * 100)
                );
              });

              const done = await parallelUploads3.done();
              if (done.ETag) {
                setShowMessage(true);
                setUploadProgress(0);
                setCanPerformUpload(false);
                setFileToUpload(null);
                setUploadBatchName("");
              }
            } catch (e) {
              console.log(e);
            }
          })();

          // fileHash(fileToUpload, md5, (x) => {
          //   const params = {
          //     Key: `${uploadBatchData.uuid}_${uploadBatchData.slug}.zip`,
          //     Bucket: dropBucketName,
          //     ContentType: fileToUpload.type,
          //     // 3. Not forget to compare ETag and File MD5
          //     // File Integrity Check not working yet
          //     // ContentMD5: x.toString(Hex),
          //     Body: fileToUpload,
          //     Metadata: {
          //       apiBaseUrl: process.env.REACT_APP_VEGEBASE_API_URL,
          //       authToken: accessToken,
          //       id: uploadBatchData.id.toString(),
          //     },
          //   };
          //   myBucket
          //     .putObject(params)
          //     .on("httpUploadProgress", (evt) => {
          //       // keeping track of  upload progress
          //       setUploadProgress(Math.round((evt.loaded / evt.total) * 100));
          //     })
          //     .on("success", (response) => {
          //       // 4. If signatures are OK, then start UploadBatch workflow (maybe send a json file with AWS S3 event notifications to trigger a lambda)
          //       console.log(response);
          //       console.log(response.data);
          //       setShowMessage(true);
          //       setUploadProgress(0);
          //       setCanPerformUpload(false);
          //       setFileToUpload(null);
          //       setUploadBatchName("");
          //     })
          //     .send((err) => {
          //       if (err) {
          //         console.log(err);
          //       }
          //     });
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // console.log("done");
      });
  };

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-gray-700">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          className="w-full"
        >
          {uploadProgress === 0 && (
            <Grid
              container
              item
              xs
              className="w-full"
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs className="w-full">
                <TextField
                  id="outlined-basic"
                  label="Nom du lot"
                  required
                  variant="outlined"
                  value={uploadBatchName}
                  onChange={handleUploadBatchNameChange}
                  className="w-full"
                  InputLabelProps={{
                    style: {
                      color: "gray",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "gray",
                      border: "solid 1px gray",
                    },
                  }}
                />
              </Grid>
              <Grid item xs className="w-full mt-2">
                <DropzoneArea
                  acceptedFiles={[
                    "application/zip",
                    "application/x-zip-compressed",
                  ]}
                  dropzoneText={
                    "Glisser/déposer le fichier archive ici ou cliquer sur la zone."
                  }
                  maxFileSize={parseInt(
                    process.env.REACT_APP_BATCH_UPLOAD_ARCHIVE_SIZE_LIMIT
                  )}
                  onChange={(files) => handleFileChange(files)}
                  onAlert={(message, variant) =>
                    console.log(`${variant}: ${message}`)
                  }
                  showFileNames={true}
                  getFileLimitExceedMessage={(filesLimit) =>
                    `Nombre limite de fichier atteint. Seulement ${filesLimit} fichier autorisé.`
                  }
                  // getDropRejectMessage={(
                  //   rejectedFile,
                  //   acceptedFiles,
                  //   maxFileSize
                  // ) => {
                  //   console.log(rejectedFile);
                  //   console.log(acceptedFiles);
                  //   console.log(maxFileSize);
                  //   return `Ajout non accepté: le type ${rejectedFile.type} n'est pas autorisé`;
                  // }}
                  filesLimit={1}
                />
              </Grid>
            </Grid>
          )}
          {uploadProgress > 0 && (
            <Grid item className="w-full">
              <LinearBufferProgressWithLabel
                value={uploadProgress}
                valueBuffer={Math.round((5242880 / fileTotalSize) * 100)}
              />
            </Grid>
          )}
          {showMessage && (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <span role="img" aria-label="success-emoji">
                  ✅
                </span>{" "}
                Le téléversement de votre fichier est terminé ! Que
                souhaitez-vous faire ?
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                    window.location.reload();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Accéder à la gestion des imports
                </Button>
                <Button
                  onClick={() => setShowMessage(false)}
                  variant="contained"
                  color="primary"
                >
                  Téléverser un nouveau fichier
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={triggerUpload}
          color="secondary"
          autoFocus
          disabled={!canPerformUpload}
        >
          {confirmButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadIllustrationsModal;
