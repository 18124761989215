import axios from "axios";
import jwtDecode from "jwt-decode";
import FuseUtils from "@fuse/FuseUtils";

class jwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          // if (
          //   err.response.status === 401 &&
          //   err.config &&
          //   !err.config.__isRetryRequest
          // ) {
          //   // if you ever get an unauthorized response, logout the user
          //   this.emit("onAutoLogout", "Invalid access_token");
          //   this.setSession(null);
          // }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_VEGEBASE_API_URL}/auth/sign_in`,
          {
            email,
            password,
          },
          {
            validateStatus: function (status) {
              return status >= 200 && status < 500; // default
            },
          }
        )
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.user.auth_token);
            resolve(response.data.user);
          } else {
            reject(response.data.errors);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  signInWithToken = (token) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_VEGEBASE_API_URL}/admin/users/me`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.user.auth_token);
            resolve(response.data.user);
          } else {
            this.logout();
            reject("Impossible de se connecter avec cette clé de session");
          }
        })
        .catch((error) => {
          this.logout();
          reject("Impossible de se connecter avec cette clé de session");
        });
    });
  };

  updateUserData = (user) => {
    return axios.post("/api/auth/user/update", {
      user: user,
    });
  };

  setSession = (access_token) => {
    let axiosInstance = axios.create({
      timeout: 10000,
    });
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axiosInstance.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${access_token}`;
          return config;
        },
        (error) => Promise.reject(error)
      );
    } else {
      // localStorage.removeItem("jwt_access_token");
      axiosInstance.interceptors.request.use(
        (config) => {
          config.headers.authorization = "Bearer";
          return config;
        },
        (error) => Promise.reject(error)
      );
    }
  };

  logout = () => {
    this.setSession(null);
    localStorage.removeItem("jwt_access_token");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    window.localStorage.getItem("jwt_access_token");
  };

  requestAccessToken = () => {
    return new Promise((resolve, reject) => {
      let accessToken = window.localStorage.getItem("jwt_access_token");
      if (accessToken) {
        resolve(accessToken);
      } else reject("token not found");
    });
  };
}

const instance = new jwtService();

export default instance;
