import React, { useState } from "react";
// import { useDebounce } from "use-debounce";
import TextField from "@material-ui/core/TextField";
import ChipInput from "material-ui-chip-input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: "#fff",
  },
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={2000} ref={ref} {...props} />;
});

const UpdateAttributeModal = ({
  open,
  message,
  dialogTitle = "Mettre à jour l'attribut",
  confirmButtonContent = "Confirmer",
  schema,
  handleClose,
  handleOpen,
  handleConfirm,
  ...rest
}) => {
  const classes = useStyles();
  const { attributes } = schema;
  const [text, setText] = useState("");
  const [attribute, setAttribute] = useState({
    name: "",
    alias: "",
    type: "String",
    options: null,
  });
  //   const [debouncedText] = useDebounce(text, 800);

  const initDefaultValue = (attribute) => {
    switch (attribute.type) {
      case "String":
        return "";
      case "String with options":
        return "";
      case "Integer":
        return 0;
      case "Boolean":
        return false;
      case "Array":
        return [];
      case "Array with options":
        return [];
      default:
        return "";
    }
  };

  const handleAttributeChange = (event) => {
    const attr = _.filter(attributes, { name: event.target.value });
    if (attr) {
      const defaultValue = initDefaultValue(attr[0]);
      setAttribute(attr[0]);
      setText(defaultValue);
      //   console.table({
      //     attribute: attr[0],
      //     value: defaultValue,
      //   });
    }
  };

  const handleValueChange = (event) => {
    switch (attribute.type) {
      case "Boolean":
        setText(event.target.checked);
        break;
      default:
        setText(event.target.value);
    }
  };

  const submitConfirm = () => {
    let params = {};
    params[attribute.name] = text;
    // alert(JSON.stringify(params));
    handleConfirm(params);
    handleClose();
  };

  const renderValueField = (attribute) => {
    switch (attribute.type) {
      case "String":
        return (
          <TextField
            id={`attribute-value`}
            label="Valeur"
            variant="outlined"
            onChange={handleValueChange}
            value={text}
            className="flex-auto w-full"
          />
        );
      case "Integer":
        return (
          <TextField
            id={`attribute-value`}
            label="Valeur"
            variant="outlined"
            onChange={handleValueChange}
            value={text}
            className="flex-auto w-full"
            type="number"
            inputProps={{ min: "0.1", max: "150", step: "0.1" }}
          />
        );
      case "Array":
        return (
          <ChipInput
            className="flex-auto w-full"
            label="Valeurs"
            value={[...text]}
            onAdd={(chip) => {
              setText([...text, chip]);
            }}
            onDelete={(chip, index) => {
              console.log(chip);
            }}
            variant="outlined"
            fullWidth
          />
        );
      case "String with options":
        return (
          <Autocomplete
            className="flex-auto w-full"
            id={`select-autocomplete-filter`}
            value={text}
            onChange={(event, newValue) => {
              setText(newValue);
            }}
            options={attribute.options}
            noOptionsText="Saisir une valeur par les propositions"
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Valeur"
                variant="outlined"
              />
            )}
          />
        );
      case "Array with options":
        return (
          <Autocomplete
            multiple
            className="flex-auto w-full"
            id={`multiple-autocomplete-filter`}
            value={[...text]}
            onChange={(event, newValue) => {
              setText(newValue);
            }}
            options={attribute.options}
            noOptionsText="Saisir une valeur par les propositions"
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Valeurs parmi..."
                variant="outlined"
              />
            )}
          />
        );
      case "Boolean":
        return (
          <FormControlLabel
            control={
              <Switch
                checked={text}
                onChange={handleValueChange}
                name="boolean-attribute"
              />
            }
            label={text ? "Oui" : "Non"}
          />
        );
      case "Datetime":
        return (
          <TextField
            id={`attribute-value`}
            label="Valeur"
            variant="outlined"
            onChange={handleValueChange}
            value={text}
            type="datetime-local"
            className="flex-auto w-full"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      default:
        return (
          <TextField
            id={`attribute-value`}
            label="Valeur"
            variant="outlined"
            onChange={handleValueChange}
            value={text}
            className="flex-auto w-full"
          />
        );
    }
  };

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-gray-700">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs className="w-full">
            {/* ATTRIBUTE */}
            <FormControl
              variant="outlined"
              className="flex-auto mr-8 w-full"
              required
            >
              <InputLabel id="select-attribute-label">Attribut</InputLabel>
              <Select
                labelId="select-attribute-label"
                id="select-attribute"
                value={attribute.name}
                onChange={handleAttributeChange}
                label="Atribut"
                className="w-full"
              >
                <MenuItem value="" disabled>
                  Sélectionner un attribut
                </MenuItem>
                {attributes.filter(attribute => attribute.isEditable === true).map((a, i) => {
                  return (
                    <MenuItem key={i} value={a.name}>
                      {a.alias}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs className="w-full">
            {/* VALUE */}
            {renderValueField(attribute)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={submitConfirm} color="secondary" autoFocus>
          {confirmButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAttributeModal;
