import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { teal } from "@material-ui/core/colors";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import { NavLinkAdapter } from "@fuse";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 500,
    marginBottom: 16
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: teal[500]
  }
}));

export default function CollectionCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let { uuid, title, image, category, isPublished } = props;
  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="collection" className={classes.avatar}>
            C
          </Avatar>
        }
        title={title}
        subheader={category}
      />
      <CardMedia className={classes.media} image={image} title={title} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the
          mussels, if you like.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="publication-status">
          {isPublished ? <PublicIcon /> : <LockIcon />}
        </IconButton>
        <IconButton aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="link"
          component={NavLinkAdapter}
          to={"/admin/c/" + uuid}
        >
          <LinkIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography gutterBottom variant="h6" color="primary" component="h6">
            Actions secondaires
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Titre de la collection
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className="w-full mx-auto mt-16 mb-16   normal-case"
            aria-label="manage-specimen"
            value="legacy"
          >
            Gérer les spécimens
          </Button>
          <Typography gutterBottom variant="h6" color="error" component="h6">
            Danger Zone
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Great powers come with great responsability
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="error"
            className="w-full mx-auto mt-16 mb-16 normal-case"
            aria-label="manage-specimen"
            value="legacy"
          >
            Supprimer
          </Button>
          <Typography variant="body2" color="textSecondary" component="p">
            Great powers come with great responsability
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="error"
            className="w-full mx-auto mt-16 mb-16 normal-case"
            aria-label="manage-specimen"
            value="legacy"
          >
            Transférer la propriété
          </Button>
        </CardContent>
      </Collapse>
    </Card>
  );
}
