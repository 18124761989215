import React from "react";
import { authRoles } from "app/auth";

export const UploadBatchesConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: ["/admin/imports/illustrations"],
      component: React.lazy(() => import("./UploadBatches/index")),
    },
    // {
    //   path: "/admin/imports/illustrations/:appKeyId",
    //   component: React.lazy(() => import("./AppKey/index")),
    // },
  ],
};
