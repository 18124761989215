import React from "react";
import { authRoles } from "app/auth";

export const AppKeysConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: ["/admin/entrees-api"],
      component: React.lazy(() => import("./AppKeys/index")),
    },
    {
      path: "/admin/k/:appKeyId",
      component: React.lazy(() => import("./AppKey/index")),
    },
  ],
};
