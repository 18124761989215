import React from "react";
import { Typography } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { Link } from "react-router-dom";

function AdminPage() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512 text-center">
        <FuseAnimate animation="transition.expandIn" delay={100}>
          <Typography variant="h1" color="inherit" className="font-medium mb-16">
            <span role="img" aria-label="locked">
              🔒
            </span>
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant="h5" color="textSecondary" className="mb-16">
            Page réservée aux administrateurs
          </Typography>
        </FuseAnimate>

        <Link className="font-medium" to="/">
          Retour à l'accueil
        </Link>
      </div>
    </div>
  );
}

export default AdminPage;
