import React from "react";
import { authRoles } from "app/auth";

export const AdminCollectionsConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.admin,
  routes: [
    {
      path: [
        "/admin/recherche-avancee/collections"
      ],
      component: React.lazy(() => import("./collections/AdminCollections"))
    },
    {
      path: "/admin/c/:collectionId",
      component: React.lazy(() => import("./collection/AdminCollection"))
    }
  ]
};
