import React from "react";
import { authRoles } from "app/auth";

export const AdvancedSearchConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: ["/admin/recherche-avancee/plantes"],
      component: React.lazy(() => import("./plants/PlantsSearch")),
    },
  ],
};
