import React from "react";
import { authRoles } from "app/auth";

export const AdminPlantsConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/admin/p/:plantId",
      component: React.lazy(() => import("./plant/AdminPlant")),
    },
    {
      path: "/admin/plantes/import-en-masse",
      exact: true,
      component: React.lazy(() => import("./bulk-upsert/BulkUpsert.js")),
    },
    {
      path: "/admin/plantes",
      component: React.lazy(() => import("./plants/AdminPlants")),
    },
  ],
};
