import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { ExampleConfig } from "app/main/example/ExampleConfig";
import { LoginConfig } from "app/main/login/LoginConfig";
import { pagesConfigs } from "app/main/pages/pagesConfigs";
import { AdminExampleConfig } from "app/main/admin/example/AdminExampleConfig";
import { AdminPlantsConfig } from "app/main/admin/plants-management/AdminPlantsConfig";
import { AdminCollectionsConfig } from "app/main/admin/collections-management/AdminCollectionsConfig";
import { AdminUsersConfig } from "app/main/admin/users-management/AdminUsersConfig";
import { AdminIllustrationsConfig } from "app/main/admin/illustrations-management/AdminIllustrationsConfig";
import { AdvancedSearchConfig } from "app/main/sandbox/advanced-search/AdvancedSearchConfig";
import { AppKeysConfig } from "app/main/admin/app-keys/AppKeysConfig";
import { UploadBatchesConfig } from "app/main/admin/upload-batches/UploadBatchesConfig";

const routeConfigs = [
  ExampleConfig,
  LoginConfig,
  AdminExampleConfig,
  AdminPlantsConfig,
  AdminCollectionsConfig,
  AdminUsersConfig,
  AdminIllustrationsConfig,
  AdvancedSearchConfig,
  AppKeysConfig,
  UploadBatchesConfig,
  ...pagesConfigs,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/public-page" />,
  },
  {
    component: () => <Redirect to="/erreur-404" />,
  },
];

export default routes;
