import React from "react";
import { Typography } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { Link } from "react-router-dom";

function PublicPage() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512 text-center">
        <FuseAnimate animation="transition.expandIn" delay={100}>
          <Typography variant="h1" color="inherit" className="font-medium mb-16">
            <span role="img" aria-label="locked">
              🙋
            </span>
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant="h5" color="textSecondary" className="mb-16">
            Page publique
          </Typography>
        </FuseAnimate>
        <FuseAnimate
          animation={{
            translateY: [0, "100%"],
            opacity: [1, 0],
          }}
          duration={400}
          delay={600}
        >
          <Link className="font-medium" to="/admin/authorized-page">
            Espace Administrateurs
          </Link>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default PublicPage;
