import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-twilight";

export default function JsonPreview(props) {
  const { content } = props;
  return (
    <AceEditor
      placeholder="Placeholder Text"
      mode="json"
      theme="twilight"
      name="blah2"
      fontSize={12}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      width="500px"
      value={JSON.stringify(content, null, "\t")}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}
