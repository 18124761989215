import React from "react";
import { authRoles } from "app/auth";

export const AdminIllustrationsConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.admin,
  routes: [
    {
      path: [
        "/admin/illustrations/filtre/:filterHandle/:illustrationId?",
        "/admin/illustrations"
      ],
      component: React.lazy(() => import("./illustrations/AdminIllustrations"))
    },
    {
      path: "/admin/i/:illustrationId",
      component: React.lazy(() => import("./illustration/AdminIllustration"))
    }
  ]
};
