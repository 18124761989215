import { authRoles } from "app/auth";

const navigationConfig = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "example-component",
        title: "Page publique",
        type: "item",
        icon: "public",
        url: "/public-page",
      },
    ],
  },
  {
    id: "admin-vegebase",
    title: "Administration Végébase",
    type: "group",
    auth: authRoles.admin,
    icon: "lock",
    children: [
      {
        id: "admin-example-component",
        title: "Réservée aux administrateurs",
        type: "item",
        auth: authRoles.admin,
        icon: "lock",
        url: "/admin/authorized-page",
      },
      {
        id: "admin-plants",
        title: "Gestion des plantes",
        type: "collapse",
        auth: authRoles.admin,
        icon: "nature_people",
        children: [
          {
            id: "admin-plants-textsearch",
            title: "Recherche textuelle",
            type: "item",
            auth: authRoles.admin,
            icon: "nature_people",
            url: "/admin/plantes",
          },
          {
            id: "plantes-advanced-search",
            title: "Recherche par filtres",
            type: "item",
            auth: authRoles.admin,
            icon: "filter_list",
            url: "/admin/recherche-avancee/plantes",
          },
          {
            id: "admin-plants-imports",
            title: "Gestion des imports",
            type: "item",
            auth: authRoles.admin,
            icon: "backup",
            url: "/admin/plantes/import-en-masse",
          },
        ],
      },
      {
        id: "admin-image-gallery",
        title: "Photothèque",
        type: "collapse",
        auth: authRoles.admin,
        icon: "local_see",
        children: [
          {
            id: "admin-gallery-search",
            title: "Recherche par filtres",
            type: "item",
            auth: authRoles.admin,
            icon: "filter_alt",
            url: "/admin/illustrations",
          },
          {
            id: "admin-gallery-imports",
            title: "Gestion des imports",
            type: "item",
            auth: authRoles.admin,
            icon: "backup",
            url: "/admin/imports/illustrations",
          },
        ],
      },
      {
        id: "admin-app-keys",
        title: "Entrées API",
        type: "item",
        auth: authRoles.admin,
        icon: "vpn_key",
        url: "/admin/entrees-api",
      },
    ],
  },
  {
    id: "admin-florisope",
    title: "Administration Floriscope",
    type: "group",
    auth: authRoles.admin,
    icon: "lock",
    children: [
      {
        id: "admin-dashboard",
        title: "Tableau de bord",
        type: "item",
        auth: authRoles.admin,
        icon: "show_chart",
        url: "/admin/dashboard",
      },
      {
        id: "admin-collections",
        title: "Gestion des collections",
        type: "collapse",
        auth: authRoles.admin,
        icon: "import_contacts",
        url: "/admin/collections",
        children: [
          {
            id: "admin-collections-api",
            title: "Recherche par filtres",
            type: "item",
            icon: "filter_list",
            url: "/admin/recherche-avancee/collections",
            exact: true,
          },
        ],
      },
      {
        id: "admin-users",
        title: "Gestion des utilisateurs",
        type: "collapse",
        auth: authRoles.admin,
        icon: "people",
        url: "/admin/users",
        children: [
          {
            id: "admin-users-api",
            title: "Recherche par filtres",
            type: "item",
            icon: "filter_list",
            url: "/admin/recherche-avancee/utilisateurs",
            exact: true,
          },
        ],
      },
    ],
  },
  {
    id: "developper-sandbox",
    title: "Developper Sandbox",
    type: "group",
    auth: authRoles.developper,
    icon: "lock",
    children: [
      {
        id: "collections-react-query",
        title: "Collections avec react-query",
        type: "item",
        auth: authRoles.developper,
        icon: "lock",
        url: "/sandbox/collections-query",
      },
      {
        id: "plantes-advanced-search",
        title: "[Plantes] Recherche avancée",
        type: "item",
        auth: authRoles.developper,
        icon: "lock",
        url: "/sandbox/recherche-avancee/plantes",
      },
    ],
  },
];

export default navigationConfig;
