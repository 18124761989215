/**
 * Authorization Roles
 */
const authRoles = {
  developper: ["developper"],
  admin: ["developper", "admin"],
  staff: ["developper", "admin", "staff"],
  user: ["developper", "admin", "staff", "user"],
  onlyGuest: [],
};

export default authRoles;
