import React from "react";
import { PublicPage } from "@floriscope";

function AdminExample() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512 text-center">
        <PublicPage></PublicPage>
      </div>
    </div>
  );
}

export default AdminExample;
