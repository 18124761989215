export const IllustrationSchema = {
  attributes: [
    {
      name: "id",
      alias: "ID",
      type: "Integer",
      options: null,
      isEditable: false,
      isFiltrable: true,
    },
    {
      name: "name",
      alias: "Nom du fichier",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "plante_taxon",
      alias: "Taxon associé",
      type: "String",
      options: null,
      isEditable: false,
      isFiltrable: true,
    },
    {
      name: "plante_id",
      alias: "ID du taxon",
      type: "Integer",
      options: null,
      isEditable: false,
      isFiltrable: true,
    },
    {
      name: "copyright",
      alias: "Copyright",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "author",
      alias: "Auteur",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "rights",
      alias: "Etat du copyright",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "high_priority",
      alias: "Prioritaire",
      type: "Boolean",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "share_scopes",
      alias: "Partage",
      type: "Array with options",
      options: ["reco", "floriscope", "commercial"],
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "legend",
      alias: "Légende",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "tags",
      alias: "Mots clés",
      type: "Array",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "batch_name",
      alias: "Nom du lot",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "intern_note",
      alias: "Commentaire",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "place",
      alias: "Adresse de prise de vue",
      type: "String",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "pictured_at",
      alias: "Date de prise de vue",
      type: "Datetime",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "published",
      alias: "Publiée",
      type: "Boolean",
      options: null,
      isEditable: true,
      isFiltrable: true,
    },
    {
      name: "archived",
      alias: "Archivée",
      type: "Boolean",
      options: null,
      isEditable: false,
      isFiltrable: true,
    },
    {
      name: "updated_at",
      alias: "Date de mise à jour",
      type: "Datetime",
      options: null,
      isEditable: false,
      isFiltrable: true,
    },
  ],
  predicates: [
    {
      name: "eq",
      alias: "égal à...",
      types: ["Float", "Integer", "String"],
      input: "text",
    },
    {
      name: "gteq",
      alias: "après le...",
      types: ["Datetime"],
      input: "date",
    },
    {
      name: "lteq",
      alias: "avant le...",
      types: ["Datetime"],
      input: "date",
    },
    {
      name: "cont",
      alias: "contient...",
      types: ["String"],
      input: "text",
    },
    {
      name: "cont_any",
      alias: "contient parmi...",
      types: ["String"],
      input: "multiple",
    },
    {
      name: "not_cont",
      alias: "ne contient pas...",
      types: ["String"],
      input: "text",
    },
    {
      name: "start",
      alias: "commence par...",
      types: ["String"],
      input: "text",
    },
    {
      name: "end",
      alias: "finit par...",
      types: ["String"],
      input: "text",
    },
    {
      name: "gt",
      alias: "supérieur à...",
      types: ["Float", "Integer"],
      input: "number",
    },
    {
      name: "gteq",
      alias: "supérieur ou égal à...",
      types: ["Float", "Integer"],
      input: "number",
    },
    {
      name: "lt",
      alias: "inférieur à...",
      types: ["Float", "Integer"],
      input: "number",
    },
    {
      name: "lteq",
      alias: "inférieur ou égal à...",
      types: ["Float", "Integer"],
      input: "number",
    },
    {
      name: "false",
      alias: "est faux",
      types: ["Boolean"],
      input: "checkbox",
    },
    {
      name: "true",
      alias: "est vrai",
      types: ["Boolean"],
      input: "checkbox",
    },
    {
      name: "array_contains",
      alias: "contient...",
      types: ["Array"],
      input: "multiple",
    },
    {
      name: "array_not_contains",
      alias: "ne contient pas...",
      types: ["Array"],
      input: "multiple",
    },
    {
      name: "array_overlap",
      alias: "se retrouve parmi...",
      types: ["Array"],
      input: "multiple",
    },
    {
      name: "array_eq",
      alias: "strictement similaire à...",
      types: ["Array"],
      input: "multiple",
    },
    {
      name: "array_not_eq",
      alias: "strictement différent de...",
      types: ["Array"],
      input: "multiple",
    },
    {
      name: "array_contains",
      alias: "contient...",
      types: ["Array with options"],
      input: "multipleAutocomplete",
    },
    {
      name: "array_not_contains",
      alias: "ne contient pas...",
      types: ["Array with options"],
      input: "multipleAutocomplete",
    },
    {
      name: "array_empty",
      alias: "ne contient pas de valeur",
      types: ["Array", "Array with options"],
      input: "checkbox",
    },
    {
      name: "array_not_empty",
      alias: "contient des valeurs",
      types: ["Array", "Array with options"],
      input: "checkbox",
    },
    {
      name: "present",
      alias: "est présent",
      types: ["String", "String with options"],
      input: "checkbox",
    },
    {
      name: "blank",
      alias: "est absent",
      types: ["String", "String with options"],
      input: "checkbox",
    },
    {
      name: "eq",
      alias: "égal à...",
      types: ["Enum"],
      input: "selectEnum",
    },
    {
      name: "null",
      alias: "est vide",
      types: [
        "String",
        "String with options",
        "Integer",
        "Float",
        "Array",
        "Boolean",
        "Enum",
      ],
      input: "checkbox",
    },
    {
      name: "not_null",
      alias: "est non-vide",
      types: [
        "String",
        "String with options",
        "Integer",
        "Float",
        "Array",
        "Boolean",
        "Enum",
      ],
      input: "checkbox",
    },
  ],
};
export default IllustrationSchema;
