import React, { useState } from "react";
import ChipInput from "material-ui-chip-input";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: "#fff",
  },
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={2000} ref={ref} {...props} />;
});

const AddTagsModal = ({
  open,
  message,
  dialogTitle = "Ajouter des mots-clés à la sélection",
  confirmButtonContent = "Confirmer",
  handleClose,
  handleOpen,
  handleConfirm,
  ...rest
}) => {
  const classes = useStyles();
  const [text, setText] = useState("");

  //   const handleValueChange = (event) => {
  //     setText(event.target.value);
  //   };

  const submitConfirm = () => {
    handleConfirm(text);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-gray-700">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs className="w-full">
            {/* TAGS */}
            <ChipInput
              className="flex-auto w-full"
              label="Valeurs"
              value={[...text]}
              onAdd={(chip) => {
                setText([...text, chip]);
              }}
              onDelete={(chip, index) => {
                console.log(chip);
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={submitConfirm} color="secondary" autoFocus>
          {confirmButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTagsModal;
