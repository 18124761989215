import React from "react";
import { authRoles } from "app/auth";

export const AdminUsersConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: ["/admin/recherche-avancee/utilisateurs"],
      component: React.lazy(() => import("./users/AdminUsers")),
    },
    {
      path: "/admin/u/:userId",
      component: React.lazy(() => import("./user/AdminUser")),
    },
  ],
};
