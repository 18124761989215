import React, { Component } from "react";
import { FuseSplashScreen } from "@fuse";
import { connect } from "react-redux";
import * as userActions from "app/auth/store/actions";
import { bindActionCreators } from "redux";
import * as Actions from "app/store/actions";
import jwtService from "app/services/jwtService";
import axios from "axios";

class Auth extends Component {
  state = {
    waitAuthCheck: true,
    waitAccessToken: true,
  };

  componentDidMount() {
    let axiosInstance = axios.create({
      timeout: 10000,
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        config.headers.authorization = localStorage.getItem("jwt_access_token");
        return config;
      },
      (error) => Promise.reject(error)
    );

    const asyncLocalStorage = {
      setItem: async function (key, value) {
        await null;
        return localStorage.setItem(key, value);
      },
      getItem: async function (key) {
        await null;
        return localStorage.getItem(key);
      },
    };

    asyncLocalStorage.getItem("jwt_access_token").then((token) => {
      jwtService.setSession(token);
      jwtService
        .signInWithToken(token)
        .then((user) => {
          this.props.setUserDataVegebase(user);
          this.props.setUserDataVegebase(user);
          this.props.showMessage({ message: "Connexion réussie." });
          this.setState({ waitAuthCheck: false });
        })
        .catch((error) => {
          this.props.showMessage({ message: error });
          this.props.logout();
          this.setState({ waitAuthCheck: false });
          // jwtService.emit("onAutoLogout", "access_token expired");
          // jwtService.setSession(null);
        });
    });
  }

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <React.Fragment children={this.props.children} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserData: userActions.setUserData,
      setUserDataVegebase: userActions.setUserDataVegebase,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
